import axios from "axios";

const _ENV = process.env;
const isProductionMode = _ENV.NODE_ENV == "production";
const url = !isProductionMode
  ? _ENV.VUE_APP_BASE_URL_API_DEV
  : _ENV.VUE_APP_BASE_URL_API_PROD;
const token = _ENV.VUE_APP_API_TOKEN;

let headersOpt = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
};

// Para añadir cabecera ACEPTANDO SOLICITUDES desde otro servidor
/* if (!isProductionMode) {
  // Configura las cabeceras CORS
  headersOpt["Access-Control-Allow-Origin"] = "https://app.sisconsystem.online";
  headersOpt["Access-Control-Allow-Methods"] = "GET, POST, PUT, PATCH, DELETE";
  headersOpt["Access-Control-Allow-Headers"] = "Content-Type, Authorization";
} */

const axiosInstance = axios.create({
  baseURL: url,
  headers: headersOpt,
});

export default axiosInstance;
