import { createRouter, createWebHistory } from "vue-router";
import Profile from "@views/Configurations/Profile.vue";
import MyBusiness from "@views/Configurations/MyBusiness.vue";
import Signin from "@views/Signin.vue";
import Categories from "@views/Products/CategoriesView.vue";
import Providers from "@views/Products/ProvidersView.vue";
import ProductsView from "@views/Products/ProductsView.vue";
import Reports from "@views/Utilities/Reports.vue";
import NewUser from "@views/Users/NewUser.vue";
import UsersView from "@views/Users/UsersView.vue";
import { checkUser } from "@/utils/manageSessions";
import Tags from "@views/Utilities/Tags.vue";
import PriceLists from "@views/Utilities/PriceLists.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Pagina principal",
    component: () => import("@views/Template/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing",
    name: "Nuevo comprobante",
    component: () => import("@views/Billing/Billing.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/credit-note",
    name: "Notas de crédito",
    component: () => import("@views/Billing/CreditNotes.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/view",
    name: "Ver ventas",
    component: () => import("@views/Billing/BillingView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/client/new",
    name: "Nuevo cliente",
    component: () => import("@views/Clients/ClientNew.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/client/credits",
    name: "Créditos y deudas",
    component: () => import("@views/Billing/CreditsDebts.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/products/new",
    name: "Nuevo producto",
    component: () => import("@views/Products/ProductsNew.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/products/edit/:id",
    name: "Editar producto",
    component: () => import("@views/Products/ProductsNew.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/products/categories",
    name: "Categorías en productos",
    component: Categories,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/products/providers",
    name: "Proovedores",
    component: Providers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/products/view/:page",
    name: "Todos los productos",
    component: ProductsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports",
    name: "Reportes",
    component: Reports,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tags",
    name: "Etiquetas",
    component: Tags,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/price-lists",
    name: "Listas de precioss",
    component: PriceLists,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Mi perfil",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/signin",
    name: "Iniciar sesión",
    component: Signin,
  },
  // {
  //   path: "/signup",
  //   name: "Registrarse",
  //   component: Signup,
  // },
  {
    path: "/my-business",
    name: "Mi empresa",
    component: MyBusiness,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/client/view",
    name: "Ver clientes",
    component: () => import("@views/Clients/ClientView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/new",
    name: "Nuevos usuarios",
    component: NewUser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/edit/:id",
    name: "Editar usuario",
    component: NewUser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/view",
    name: "Lista de usuarios",
    component: UsersView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@views/Template/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isLoggedIn = await checkUser();
    if (!isLoggedIn) {
      next("/signin");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
