<script setup>
// import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const sidebarMinimize = () => store.commit("sidebarMinimize");

const minimizeSidebar = () => {
  if (window.innerWidth < 1200) {
    sidebarMinimize();
  }
};

/* const scrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth', // Opcional: desplazamiento suave
  }); */

defineProps({
  to: {
    type: String,
    required: true,
  },
  navText: {
    type: String,
    required: true,
  },
});
</script>
<template>
  <router-link :to="to" class="nav-link" @click="minimizeSidebar">
    <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text ms-1">{{
      navText
    }}</span>
  </router-link>
</template>
