import Swal from "sweetalert2";

export const showModal = ({
  title,
  isHtml = false,
  text = "",
  icon = "",
  cancelText = "Cancelar",
  confirmText = "Confirmar",
  denyText = "",
  showConfirm = true,
  showCancel = true,
  showDeny = false,
  outClick = false,
  width = false,
}) => {
  const objSwal = {
    title: title,
    allowOutsideClick: outClick,
    showCancelButton: showCancel,
    cancelButtonText: cancelText,
    showConfirmButton: showConfirm,
    confirmButtonText: confirmText,
    icon: icon,
    customClass: {
      confirmButton: "btn btn-info btn-sweet-custom text-lg me-3",
      denyButton: "btn btn-warning text-lg me-3",
      cancelButton: "btn btn-secondary text-lg me-3",
    },
    buttonsStyling: false,
    showClass: {
      popup: "customSwal2",
    },
  };

  if (isHtml) {
    objSwal.html = text;
  } else {
    objSwal.text = text;
  }

  if (showDeny) {
    objSwal.showDenyButton = true;
    objSwal.denyButtonText = denyText;
  }

  if (width) {
    objSwal.width = width;
  }

  return Swal.fire(objSwal);
};

export const showToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: false,

  didOpen: (toast) => {
    toast.onmouseover = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
    toast.onclick = Swal.close;
  },
});
