<script setup>
import { onBeforeMount, onMounted, onBeforeUnmount, computed } from "vue";
import { useStore } from "vuex";

import setTooltip from "@/assets/js/tooltip.js";
import ProfileBusinessCard from "@/views/components/ProfileBusinessCard.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import iconImg from '@/assets/img/icon-rounded.png';

const store = useStore();
const imageOnStore = store.state.imageIconBusiness;
const iconBusiness = computed(() => imageOnStore !== '' ? imageOnStore : iconImg);

onMounted(async () => {
    setTooltip();
    store.state.openedMenu = '';
});
onBeforeMount(() => {
    store.state.layout = 'business';
    store.state.openedMenu = '';
});
onBeforeUnmount(() => {
    store.state.layout = 'default';
});
</script>
<template>
    <main>
        <div class="container-fluid">
            <div class="page-header min-height-200" />
            <div class="card shadow-lg mt-n6 px-2">
                <div class="card-body p-3">
                    <div class="row gx-4">
                        <div class="col-auto">
                            <div class="avatar avatar-xl position-relative">
                                <img :src="iconBusiness" alt="My business logo"
                                    class="shadow-sm w-100 border-radius-lg" />
                            </div>
                        </div>
                        <div class="col-auto my-auto">
                            <div class="h-100">
                                <h5 class="mb-1">Siscon Systems</h5>
                                <p class="mb-0 font-weight-bold text-sm">The best system in the world</p>
                            </div>
                        </div>
                        <div class="mx-auto mt-3 col-lg-7 col-md-8 my-sm-auto ms-sm-auto me-sm-0">
                            <div class="nav-wrapper d-flex flex-row align-items-center justify-content-end gap-3">
                                <argon-button id="showInfo" color="info" size="md" variant="outline" :active="true"
                                    :activating="true" class="d-flex flex-row gap-3 align-items-center">
                                    <i class="fa-solid fa-database"></i>
                                    Información
                                </argon-button>
                                <argon-button id="showInfo" color="warning" size="md" variant="outline"
                                    :activating="true" class="d-flex flex-row gap-3 align-items-center">
                                    <i class="fa-solid fa-link"></i>
                                    Integraciones
                                </argon-button>
                                <argon-button id="showInfo" color="danger" size="md" variant="outline"
                                    :activating="true" class="d-flex flex-row gap-3 align-items-center">
                                    <i class="fa-solid fa-gears"></i>
                                    Configuraciones
                                </argon-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-4 container-fluid">
            <div class="row">
                <div class="col-md-8">
                    <div class="card">
                        <!-- <div class="card-header pb-0">
                            <div class="d-flex align-items-center">
                                <p class="mb-0">Editar Mi Empresa</p>
                            </div>
                        </div> -->
                        <div class="card-body">
                            <p class="text-uppercase text-sm">Información de mi empresa</p>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Nombre</label>
                                    <argon-input type="text" modelValue="Siscon" />
                                </div>
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Correo
                                        electrónico</label>
                                    <argon-input type="email" modelValue="info@sisconsystem.online" />
                                </div>
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Razón social</label>
                                    <argon-input type="text" modelValue="Siscon System S.A." />
                                </div>
                                <div class="col-md-6">
                                    <label for="example-text-input" class="form-control-label">Last name</label>
                                    <argon-input type="text" modelValue="Lucky" />
                                </div>
                            </div>
                            <hr class="horizontal dark" />
                            <p class="text-uppercase text-sm">Información de contacto</p>
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="example-text-input" class="form-control-label">Dirección</label>
                                    <argon-input type="text" modelValue="Callejón Rodríguez 1365" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Ciudad</label>
                                    <argon-input type="text" modelValue="San Martín" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">País</label>
                                    <argon-input type="text" modelValue="Argentina" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Código postal</label>
                                    <argon-input type="text" modelValue="437300" />
                                </div>
                                <div class="col-md-4">
                                    <label for="example-text-input" class="form-control-label">Teléfono</label>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <span class="input-group-text">
                                                <i class="fa-solid fa-phone"></i>
                                            </span>
                                            <input type="text" class="form-control" value="(263) 440 7264" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="horizontal dark" />
                            <p class="text-uppercase text-sm">Sobre nosotros</p>
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="example-text-input" class="form-control-label">Resumen</label>
                                    <argon-input type="text"
                                        modelValue="A beautiful Dashboard for Bootstrap 5. It is Free and Open Source." />
                                </div>
                            </div>
                            <hr class="horizontal dark" />
                            <div class="row d-flex justify-content-center mt-4+">
                                <div class="w-30">
                                    <argon-button color="info" size="md" class="ms-auto w-100">Guardar</argon-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <profile-business-card class="position-sticky top-8 z-index-sticky" />
                </div>
            </div>
        </div>
    </main>
</template>
