import { makeGetRequest } from "./querysAPI.js";

const urlCheckUser = "/users/check/";

export const loginUser = (
  userId,
  userImgAvatar,
  userRole,
  userFullName,
  businessId
) => {
  sessionStorage.setItem(
    "userSession",
    JSON.stringify({
      userId: userId,
      userImgAvatar: userImgAvatar,
      userRole: userRole,
      userFullName: userFullName,
      businessId: businessId,
    })
  );
};

export const logoutUser = () => {
  if (localStorage.getItem("remember_token")) {
    localStorage.removeItem("remember_token");
  }
  sessionStorage.removeItem("userSession");
};

export const checkUser = async () => {
  const session = sessionStorage.getItem("userSession");

  if (!session) {
    logoutUser();
    return false;
  }

  return true;
};

// Hace lo mismo que checkUser pero consulta los datos al server
export const checkUserOnline = async () => {
  const session = sessionStorage.getItem("userSession");

  if (!session) {
    logoutUser();
    return false;
  }

  const userSession = JSON.parse(session);

  const requestResponse = await makeGetRequest(
    urlCheckUser + userSession.userId
  );

  if (!requestResponse.success) {
    // Trigger logout for security
    logoutUser();
    return false;
  }

  const checkedId = userSession.userId === requestResponse.data.userId;
  const checkedRole = userSession.userRole === requestResponse.data.userRole;

  if (!checkedId && !checkedRole) {
    logoutUser();
    return false;
  }
  return true;
};

export const getDataSession = () => {
  return JSON.parse(sessionStorage.getItem("userSession"));
};
