import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "@/router";
import "@assets/css/nucleo-icons.css";
import "@assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import ElementPlus from "element-plus";
import esAr from "element-plus/es/locale/lang/es";
import "element-plus/dist/index.css";
// TODO: continuar configurando ws
// import VueNativeSock from "vue-native-websocket-vue3";

import "sweetalert2/dist/sweetalert2.min.css";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(ElementPlus, {
  locale: esAr,
});
// TODO: continuar configurando ws
/* appInstance.use(VueNativeSock, "ws://your-websocket-url", {
  format: "json",
  reconnection: true, // Reintentar en caso de desconexión
  reconnectionAttempts: 5, // Número de intentos de reconexión
  reconnectionDelay: 3000, // Retraso entre intentos de reconexión
}); */
appInstance.mount("#app");
