<script setup>
const activeButton = (id) => {
  const classBtn = document.getElementById(id).classList;

  if (classBtn.value.includes('active')) {
    classBtn.remove('active');
  } else {
    classBtn.add('active');
  }
};

defineProps({
  color: {
    type: String,
    default: "success",
  },
  size: {
    type: String,
    default: "md",
  },
  variant: {
    type: String,
    default: "fill",
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  activating: {
    type: Boolean,
    default: false,
  },
  refBtn: {
    type: String,
    default: '',
  },
});
const getClasses = (variant, color, size, fullWidth, active) => {
  let colorValue, sizeValue, fullWidthValue, activeValue;

  // Setting the button variant and color
  if (variant === "gradient") {
    colorValue = `bg-gradient-${color}`;
  } else if (variant === "outline") {
    colorValue = `btn-outline-${color}`;
  } else {
    colorValue = `btn-${color}`;
  }

  sizeValue = size ? `btn-${size}` : null;

  fullWidthValue = fullWidth ? `w-100` : null;

  activeValue = active ? `active` : null;

  return `${colorValue} ${sizeValue} ${fullWidthValue} ${activeValue}`;
};
</script>
<template>
  <button v-if="activating" :id="refBtn" class="btn mb-0 btnToActivate" @click="activeButton(refBtn)"
    :class="getClasses(variant, color, size, fullWidth, active)">
    <slot />
  </button>
  <button v-else :id="refBtn" class="btn mb-0 btnToActivate"
    :class="getClasses(variant, color, size, fullWidth, active)">
    <slot />
  </button>
</template>
