<script setup>
import { useStore } from "vuex";
const store = useStore();
</script>
<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-center">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div class="text-sm text-center copyright text-muted"
            :class="store.state.isRTL ? 'text-lg-end' : 'text-lg-start'">
            ©
            {{ new Date().getFullYear() }},
            Desarrollado por:
            <a href="https://www.linkedin.com/in/alexis-gabriel-sanchez/" class="font-weight-bold"
              target="_blank">Alexis
              Gabriel Sanchez</a><!-- <i class="fa-solid fa-code"></i> -->. Todos los derechos reservados.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
