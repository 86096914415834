import bootstrap from "bootstrap/dist/js/bootstrap";

// initialization of Tooltips
/* export default function setTooltip() {
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]'),
  );
  // eslint-disable-next-line no-unused-vars
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
} */

// Inicialización de los tooltips
export default function setTooltip() {
  // Buscar y destruir todos los tooltips existentes
  const tooltips = document.querySelectorAll(".tooltip");
  tooltips.forEach((tooltip) => {
    const instance = bootstrap.Tooltip.getInstance(tooltip);
    if (instance) {
      instance.dispose();
    }
  });

  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );

  tooltipTriggerList.forEach((tooltipTriggerEl) => {
    const tooltip = new bootstrap.Tooltip(tooltipTriggerEl);

    // Agregar un evento de clic al elemento del tooltip
    tooltipTriggerEl.addEventListener("click", () => {
      // Ocultar el tooltip
      tooltip.hide();
    });
  });
}
