<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/icon-rounded-100.png";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const layout = computed(() => store.state.layout);
const sidebarType = computed(() => store.state.sidebarType);
const backgroundImage = computed(() => {
  const image = store.state.imageBackgroundNav;
  if (image !== '') {
    return `
      background-image: url(${image});
      background-size: cover;
      background-position: center;
    `
  }
  return false;
});
const backgroundColor = store.state.backgroundColor;
</script>
<template>
  <div class="min-height-400 position-fixed top-0 w-100"
    :class="!backgroundImage ? 'bg-gradient-' + backgroundColor : ''" :style="backgroundImage">
    <span v-if="backgroundImage"
      :class="`${layout == 'business' ? 'bg-gradient-dark' : 'bg-gradient-' + backgroundColor}`"
      class="mask opacity-7"></span>
  </div>

  <aside class="overflow-auto shadow-xl border-0 sidenav navbar navbar-vertical navbar-expand-xs" :class="`${isRTL ? 'rotate-caret fixed-end' : 'fixed-start'}    
      ${layout === 'landing' ? 'bg-transparent' : ' '
    } ${sidebarType}`" id="sidenav-main">
    <div class="sidenav-header">
      <i class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true" id="iconSidenav"></i>

      <router-link class="m-0 navbar-brand" to="/">
        <!-- <img :src="darkMode || sidebarType === 'bg-default' ? logoWhite : logo" class="navbar-brand-img h-100" -->
        <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />

        <span class="ms-2 font-weight-bold me-2">Siscon System</span>
      </router-link>
    </div>

    <hr class="mt-0 horizontal dark" />

    <sidenav-list />
  </aside>
</template>
<style scoped>
#sidenav-main {
  scrollbar-width: none;
  /* max-width: 18rem!important; */
  z-index: 999 !important;
}
</style>
