import { DateTime } from "luxon";

/* const hasTwoDecimals = (event) => {
  const value = event.target.value;
  const decimalPos = value.indexOf(".");

  if (decimalPos > 0) {
    const splitted = value.split(".");
    const decimal = splitted[1];
    if (decimal.length > 2) {
      return false;
    }
  }

  return true;
}; */

export const parseTowDecimals = (number) => {
  return parseFloat(number).toFixed(2);
};

export function onlyNumber(event) {
  const keyCodes = [8, 46, 35, 36, 37, 38, 39, 40];
  // const value = event.target.value;
  // const decimalPos = value.indexOf(".");

  // Permite teclas de control y números
  if (!/\d/.test(event.key) && !keyCodes.includes(event.keyCode)) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  } /* 

  // Verifica si ya hay dos decimales
  if (decimalPos > 0) {
    const splitted = value.split(".");
    const decimal = splitted[1];
    console.log(decimal);
    if (decimal.length > 2) {
      event.preventDefault();
      return false;
    }
  } */

  return true;
}

export function formatInputValue(event) {
  let value = event.target.value;

  // Asegúrate de que el valor tenga dos decimales
  if (value && !value.includes(".")) {
    value += ".00";
  } else if (value && value.includes(".")) {
    const [integer, decimals] = value.split(".");
    if (!decimals || decimals.length < 2) {
      value = `${integer}.${decimals || ""}00`.slice(0, integer.length + 3);
    }
  }

  // Actualiza el valor del input
  event.target.value = value;
}

export function selectInputText(event) {
  event.target.select();
}

export const formatNumber = (number, min = 2, max = 2) => {
  return Number(number).toLocaleString("es-AR", {
    style: "decimal",
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
};

export const unformatNumber = (number) => {
  const newNumber = parseFloat(
    String(number).replace(/\./g, "").replace(/,/g, ".")
  );
  return !isNaN(newNumber) ? newNumber : 0;
};

export const formatDate = (dateStr, format) => {
  const newDate = DateTime.fromISO(dateStr);
  return newDate.toFormat(format);
};

export const formatDateFromJS = (dateStr, format) => {
  const newDate = DateTime.fromJSDate(dateStr);
  return newDate.toFormat(format);
};

export function formatPhoneNumber(phoneNumber) {
  const cleanedPhoneNumber = String(phoneNumber.target.value).replace(
    /\D/g,
    ""
  );
  // console.log(cleanedPhoneNumber);
  if (!/^\d+$/.test(cleanedPhoneNumber)) {
    return phoneNumber.target.value;
  }

  if (cleanedPhoneNumber.length >= 10) {
    const areaCode = cleanedPhoneNumber.slice(0, 3);
    const firstPart = cleanedPhoneNumber.slice(3, 6);
    const secondPart = cleanedPhoneNumber.slice(6, 10);
    return `(${areaCode}) ${firstPart} ${secondPart}`;
  } else {
    return cleanedPhoneNumber;
  }
}
