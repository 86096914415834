<script setup>
const emit = defineEmits(["update:modelValue"]);

defineProps({
  size: {
    type: String,
    default: "default",
  },
  success: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "",
  },
  iconDir: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  modelValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  autoComplete: {
    type: String,
    default: ''
  },
  max: {
    type: String,
    default: ''
  },
  maxLength: {
    type: String,
    default: ''
  },
  textPosition: {
    type: String,
    default: '',
  },
  withStep: {
    type: String,
    default: '',
  },
});

const getClasses = (size, success, error, textPosition) => {
  let sizeValue, isValidValue;

  sizeValue = size ? `form-control-${size}` : null;

  if (error) {
    isValidValue = "is-invalid pe-5";
  } else if (success) {
    isValidValue = "is-valid pe-5";
  } else {
    isValidValue = "";
  }

  return `${sizeValue} ${isValidValue} ${textPosition}`;
};
const getIcon = (icon) => (icon ? icon : null);
const hasIcon = (icon) => (icon ? "input-group" : null);
</script>
<template>
  <div class="form-group">
    <div :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <input :id="id" :type="type" class="form-control" :class="getClasses(size, success, error, textPosition)"
        :name="name" :value="modelValue" :placeholder="placeholder" :required="isRequired"
        :autocomplete="autoComplete !== '' ? autoComplete : 'off'" :step="withStep"
        @input="emit('update:modelValue', $event.target.value)" />
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>
  </div>
</template>
<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
