import { createStore } from "vuex";

export default createStore({
  state: {
    // TODO: Roles system
    userRole: "OWNER", // owner, administrator, seller, others
    hideConfigButton: false,
    connectedToInternet: true,
    isPinned: false,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    openedMenu: "",
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    showMessages: true,
    layout: "default",
    salesDropDown: false,
    itemsPerPage: 20,
    IVA: 21,
    maxAmmountFinalConsumer: 0,
    isAfipEnabled: false,
    idPayment: {
      cash: "payCash",
      tc: "payTC",
      td: "payTD",
      mp: "payMP",
    },
    billing: {
      products: [],
      totals: {},
      discounts: [
        {
          ammount: 0,
          id: null,
        },
      ],
      billType: "receipt",
      billLetter: "r",
      client: null,
      payments: [
        {
          typePayment: "CASH",
          ammount: null,
          authorizationCode: null,
        },
      ],
      sendAFIP: false,
      notes: null,
    },
    billingLoader: true,
    backgroundColor: "info",
    /* imageAvatar:
      "https://images.unsplash.com/photo-1599566150163-29194dcaad36?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", */
    imageIconBusiness: "",
    imageBackgroundNav:
      "https://images.unsplash.com/39/lIZrwvbeRuuzqOoWJUEn_Photoaday_CSD%20%281%20of%201%29-5.jpg?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    sidebarMinimize(state) {
      let sidenav_show = document.querySelector("#app");
      if (state.isPinned) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    addProductToStoring(state, prod) {
      const products = state.billing.products;
      if (products.length > 0) {
        const productInState = products.find(
          (product) => product.productId === prod.productId
        );
        if (!productInState) {
          state.billing.products.push({
            id: prod.productId,
            qty: 1,
            total: prod.sellPrice,
          });
        }
      } else {
        state.billing.products.push({
          id: prod.productId,
          qty: 1,
          total: prod.sellPrice,
        });
      }
    },
    removeProductFromStoring(state, id) {
      const products = state.billing.products;
      const indexProduct = products.findIndex((product) => product.id === id);
      if (indexProduct !== -1) {
        state.billing.products.splice(indexProduct, 1);
      }
    },
    modifyQtyProduct(state, payload) {
      console.log(payload);
      const products = state.billing.products;
      if (products.length > 0) {
        const productInState = products.find(
          (product) => product.id === payload.productId
        );
        if (productInState) {
          console.log(payload.qty);
          const newTotal = payload.qty * payload.sellPrice;
          productInState.qty = payload.qty;
          if (productInState.qty < 0) productInState.qty = 0; // Asegura que la cantidad no sea negativa
          productInState.total = newTotal;
        }
        console.log(state.billing.products);
      }
    },
    addTotalsToBilling(state, payload) {
      state.billing.totals = payload;
    },
    resetBillingData(state) {
      state.billing = {
        products: [],
        totals: {},
        discounts: [
          {
            ammount: 0,
            id: null,
          },
        ],
        billType: "receipt",
        billLetter: "r",
        client: null,
        payments: [
          {
            typePayment: "CASH",
            ammount: null,
            authorizationCode: null,
          },
        ],
        sendAFIP: false,
        notes: null,
      };
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.userSession.userId,
    defaulPaymentMethod: (state) => state.idPayment.cash,
  },
});
