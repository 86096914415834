<script setup>
import { RouterLink } from 'vue-router';
import NoProductSVG from '@assets/img/illustrations/no-product.svg';
import NoDocumentSVG from '@assets/img/illustrations/no-document.svg';
import UnderConstructionSVG from '@assets/img/illustrations/under-construction.svg';

const getSVG = (name) => {
    const svgMap = {
        NoProductSVG,
        NoDocumentSVG,
        UnderConstructionSVG
    };
    return svgMap[name];
};

const getClasses = (width, height) => {
    return `w-${width} h-${height}`;
};

defineProps({
    text: {
        type: String,
        default: 'Not found document'
    },
    redirectTo: {
        type: String,
        default: ''
    },
    redirectText: {
        type: String,
        default: ''
    },
    iconSVG: {
        type: String,
        required: true
    },
    widthClass: {
        type: String,
        default: '30'
    },
    heightClass: {
        type: String,
        default: '30'
    },
});
</script>
<template>
    <div class="d-flex justify-content-center flex-column align-items-center my-5 mx-5">
        <img :src="getSVG(iconSVG)" :class="getClasses(widthClass, heightClass)" alt="No ducument found">
        <h6 class="text-sm text-center text-muted mt-4">{{ text }}</h6>
        <div v-show="redirectTo !== ''" class="mt-4">
            <router-link :to="redirectTo"
                class="btn font-weight-bold text-white bg-dark d-flex align-items-center justify-content-center">
                <i class="fa-solid fa-plus text-lg me-2"></i>
                <span class="d-sm-inline d-none">{{ redirectText }}</span>
            </router-link>
        </div>
        <slot />
    </div>
</template>
<style scoped>
img {
    filter: grayscale(100%);
}
</style>